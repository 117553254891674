import React, { useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { useStyles } from "./CardSelectInterested.style";
import { ButtonPrimary } from "@components/Reusables";
import { updateUser } from "@apollo";
import { useApolloClient } from "@apollo/client";
import { useForm } from "@hooks";
import { preferencesList } from "@data";
import { currentUserPreferences } from "@apollo";
import AnalysisResults from "../analysisResults/AnalysisResults";
import { financialAnalysisInterface, financialAnalysisResponse, financialEndOptions } from "@interfaces";
import { financialAnalysis } from "@utils";
import { updateUserIdeaFinancialData } from "apollo/user_financial_data/updateUserIdeaFinancialData.mutation";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

interface CardSelectInterestedProps {
  onContinueClick: () => void;
  userIdea: financialAnalysisInterface;
  setEndOptions: (obj: financialEndOptions) => void;
}

const CardSelectInterested = ({
  onContinueClick,
  userIdea,
  setEndOptions,
}: CardSelectInterestedProps) => {
  const isMobile = useMediaQuery("(max-width: 1440px)");
  const classes = useStyles();
  const preferences = currentUserPreferences();
  const client = useApolloClient();
  const [form] = useForm({});
  const budgetGoals = preferences?.budgetGoals || [];
  const [isSelect, setSelect] = useState({
    card: false,
    card1: false,
    card2: false,
    card3: false,
    card4: false,
    card5: false,
    card6: false,
    card7: false,
  });
  const [onBoarding, setOnBoarding] = useState([]);
  const onSelectCard = useCallback(
    (id: number) => {
      id === 0 &&
        setSelect((prev) => ({
          ...prev,
          card: !isSelect.card,
        }));

      id === 1 &&
        setSelect((prev) => ({
          ...prev,
          card1: !isSelect.card1,
        }));

      id === 2 &&
        setSelect((prev) => ({
          ...prev,
          card2: !isSelect.card2,
        }));

      id === 3 &&
        setSelect((prev) => ({
          ...prev,
          card3: !isSelect.card3,
        }));

      id === 4 &&
        setSelect((prev) => ({
          ...prev,
          card4: !isSelect.card4,
        }));

      id === 5 &&
        setSelect((prev) => ({
          ...prev,
          card5: !isSelect.card5,
        }));

      id === 6 &&
        setSelect((prev) => ({
          ...prev,
          card6: !isSelect.card6,
        }));

      id === 7 &&
        setSelect((prev) => ({
          ...prev,
          card7: !isSelect.card7,
        }));
    },
    [isSelect]
  );

  const appendSelectCard = useCallback(
    (id: number) => {
      onBoarding.filter((item) => item === id).length === 0
        ? setOnBoarding([...onBoarding, id])
        : setOnBoarding(onBoarding.filter((item) => item !== id));
    },
    [onBoarding]
  );

  const analysisScore = financialAnalysis(userIdea.averageIncome, userIdea.fixedExpenses, userIdea.variableExpenses, userIdea.savings, userIdea.debts, 35, "average")

  const handleOnContinueClick = useCallback(() => {
    form.preferences = {
      budgetGoals: budgetGoals,
      onBoarding: onBoarding,
    };
    updateUser(form, true, client);
    updateUserIdeaFinancialData(client, userIdea);
    setEndOptions({
      status: analysisScore.status,
      defaultDebt: userIdea.defaultDebts, 
      preferences: onBoarding,
    })
    onContinueClick();
  }, [form, onBoarding, budgetGoals, client, onContinueClick]);

  return (
    <>
      <div className={classes.mainContainer}>
        <AnalysisResults
          analysisScore={analysisScore}
          isTitle
        />
        <section className={classes.containerRight}>
          <div>
            <p className={classes.upperRightText}>
              ¿Qué quieres lograr con tus finanzas?
            </p>
            <p className={classes.lowerRightText}>Selecciona los que más prefieras</p>
          </div>
          <div className={classes.containerCard}>
            {preferencesList(isSelect).map((card) => {
              return (
                <div
                  className={`${classes.card} 
                  ${card.select && classes.select} 
                `}
                  key={card.id}
                  onClick={() => {
                    onSelectCard(card.id);
                    appendSelectCard(card.id);
                  }}
                >
                  {card.select ? (
                    <>
                      <img className={classes.imgSelected} src={card.imgSelect} />
                      <CheckCircleRoundedIcon className={classes.checkIcon} />
                    </>
                  ) : (
                    <img src={card.img} />
                  )}
                  <span>{card.title}</span>
                </div>
              );
            })}
          </div>
          <div className={classes.button}>
            <ButtonPrimary
              noDegraded
              text="Continuar"
              onClick={() => {
                handleOnContinueClick();
              }}
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default CardSelectInterested;
