import React, { useState } from "react";
import { navigate, PageProps } from "gatsby";
import { useMediaQuery } from "@material-ui/core";
import { SEO } from "@components";
import LayoutProductTour from "@components/ProductTour/Layout/LayoutProductTour";
import CardDataProductTour from "@components/ProductTour/CardDataProductTour/CardDataProductTour";
import { SLIDE_1, SLIDE_2, SLIDE_3 } from "images/AWS/productTour";
import CardSelectInterested from "@components/ProductTour/CardSelectInterested/CardSelectInterested";
import {
  Route,
  financialAnalysisInterface,
  financialEndOptions,
} from "@interfaces";
import CardAssessment from "@components/ProductTour/CradAssessment/CardAssessment";
import { useAuth } from "@hooks";
import YouAreSet from "@components/ProductTour/YouAreSet/YouAreSet";

const productTour: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });

  const isMobile = useMediaQuery("(max-width: 1440px)");
  const [numberStep, setNumberStep] = useState(0);
  const [userIdea, setUserIdea] = useState<financialAnalysisInterface>({
    averageIncome: 0,
    debts: 0,
    defaultDebts: false,
    fixedExpenses: 0,
    savings: 0,
    variableExpenses: 0,
  });
  const [endOptions, setEndOptions] = useState<financialEndOptions>({
    status: "Saludable",
    defaultDebt: false,
    preferences: [0],
  });

  let title;
  let subtitleBold;
  let subtitle;
  let image;
  let color;

  switch (numberStep) {
    case 0:
      title = listSteps[0].title;
      subtitleBold = listSteps[0].subtitleBold;
      subtitle = listSteps[0].subtitle;
      image = listSteps[0].image;
      color = listSteps[0].color;

      break;
    case 1:
      title = listSteps[1].title;
      subtitleBold = listSteps[1].subtitleBold;
      subtitle = listSteps[1].subtitle;
      image = listSteps[1].image;
      color = listSteps[1].color;

      break;
    case 2:
      title = listSteps[2].title;
      subtitleBold = listSteps[2].subtitleBold;
      subtitle = listSteps[2].subtitle;
      image = listSteps[2].image;
      color = listSteps[2].color;

      break;

    case 5:
      color = listSteps[5].color;
      break;

    default:
      color = listSteps[0].color;

      break;
  }

  return (
    <div>
      <SEO
        title="¡Bienvenido a Rokin! Primer paso para mejorar tus finanzas."
        description="Conoce tu situación financiera y comienza a trabajar para unas finanzas más saludables hoy."
      />
      {numberStep < 2 && (
        <CardDataProductTour
          title={title || ""}
          subtitleBold={subtitleBold || ""}
          subtitle={subtitle || ""}
          image={image || ""}
          step={numberStep}
          setStep={setNumberStep}
        />
      )}
      {numberStep >= 2 && numberStep !== 5 && (
        <LayoutProductTour
          step={numberStep}
          amountSteps={3}
          onContinueClick={() => setNumberStep(numberStep + 1)}
          setNumberStep={setNumberStep}
          onOmitClick={() => navigate(Route.finance)}
          color={color || ""}
          stepTop={numberStep - 2}
          omit={numberStep > 0}
          disabledOmit={numberStep === 0}
        >
          {(numberStep === 2 || numberStep === 3) && (
            <CardAssessment setUserIdea={setUserIdea} step={numberStep} />
          )}
          {numberStep === 4 && (
            <CardSelectInterested
              onContinueClick={() => setNumberStep(numberStep + 1)}
              userIdea={userIdea}
              setEndOptions={setEndOptions}
            />
          )}
        </LayoutProductTour>
      )}
      {numberStep === 5 && (
        <YouAreSet
          onContinueClick={() => setNumberStep(numberStep + 1)}
          endOptions={endOptions}
        />
      )}
    </div>
  );
};

export default productTour;

const listSteps = [
  {
    id: 0,
    title: "¿Tienes algún convenio para activar? (empresa u otro)",
    subtitleBold: "",
    subtitle:
      "Confirmaremos que pertenezcas al convenio para activar tus beneficios.",
    image: SLIDE_1,
    color: "#008296",
  },
  {
    id: 1,
    title: (
      <>
        Para que no vuelvas <br />a pagar de más
      </>
    ),
    subtitleBold: "",
    subtitle:
      "Toma el control de tus gastos, potencia tu ahorro y descubre las mejores alternativas y descuentos en diferentes servicios.",
    image: SLIDE_2,
    color: "#8352fd",
  },
  {
    id: 2,
    title: "Hecho a tu medida",
    subtitleBold: "Tu camino es único. ",
    subtitle:
      "Encuentra herramientas y un acompañamiento personalizado para lo que tu necesitas.",
    image: SLIDE_3,
    color: "#008296",
  },
  {
    id: 3,
    color: "#008296",
  },
  { id: 4 },
  { id: 5 },
  { id: 6, color: "#084554" },
];
