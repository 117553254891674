import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { useStyles } from "./YouAreSet.style";
import ArrowBackIosNewIcon from "@material-ui/icons/ArrowBackIos";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import {
  CHECK_IMAGE,
  PERSONAL_TRAINERS,
  CALENDAR_ICON,
  SETTINGS_ICON,
} from "images/AWS/productTour";
import { ButtonOutlined, ButtonPrimary } from "@components/Reusables";
import { Route } from "interfaces/routes";
import { navigate } from "gatsby";
import { financialEndOptions } from "@interfaces";

interface CardIMetCarlosProps {
  onContinueClick: () => void;
  endOptions: financialEndOptions;
}

const YouAreSet = ({ onContinueClick, endOptions }: CardIMetCarlosProps) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1025px)");
  const [cardsCase, setCardsCase] = useState(1);

  useEffect(() => {
    if (
      endOptions.status === "Crítico" ||
      endOptions.defaultDebt == true ||
      endOptions.preferences.includes(0)
    ) {
      setCardsCase(1);
    } else if (
      endOptions.status === "En riesgo" ||
      endOptions.preferences.includes(3)
    ) {
      setCardsCase(2);
    } else if (
      endOptions.status === "Saludable" ||
      endOptions.preferences.includes(5) ||
      endOptions.preferences.includes(7)
    ) {
      setCardsCase(3);
    } else if (
      endOptions.status === "Con potencial" ||
      endOptions.preferences.includes(6) ||
      endOptions.preferences.includes(5)
    ) {
      setCardsCase(4);
    } else if (
      endOptions.status === "En riesgo" ||
      endOptions.preferences.includes(1) ||
      endOptions.preferences.includes(2)
    ) {
      setCardsCase(5);
    } else {
      setCardsCase(1);
    }
  }, [endOptions]);

  const getServicesCards = (arrayNumber: number) => {
    let auxArray;
    switch (arrayNumber) {
      case 1:
        auxArray = servicesListCase1;
        break;
      case 2:
        auxArray = servicesListCase2;
        break;
      case 3:
        auxArray = servicesListCase3;
        break;
      case 4:
        auxArray = servicesListCase4;
        break;
      case 5:
        auxArray = servicesListCase5;
        break;
    }
    const arrayOfCards = auxArray?.map((item) => (
      <>
        <div className={classes.card} onClick={() => navigate(item.link)}>
          <div className={classes.gear}>
            <img src={SETTINGS_ICON} alt="" />
          </div>
          <div className={classes.cardTextContainer}>
            <p className={classes.upperCardText}>{item.title}</p>
            <p className={classes.lowerCardText}>{item.subtitle}</p>
          </div>
        </div>
      </>
    ));
    return arrayOfCards;
  };

  const getToolCards = (arrayNumber: number) => {
    let auxArray;
    switch (arrayNumber) {
      case 1:
        auxArray = toolsListCase1;
        break;
      case 2:
        auxArray = toolsListCase2;
        break;
      case 3:
        auxArray = toolsListCase3;
        break;
      case 4:
        auxArray = toolsListCase4;
        break;
      case 5:
        auxArray = toolsListCase5;
        break;
    }
    const arrayOfCards = auxArray?.map((item) => (
      <>
        <div className={classes.card} onClick={() => navigate(item.link)}>
          <div className={classes.gear}>
            <img src={SETTINGS_ICON} alt="" />
          </div>
          <div className={classes.cardTextContainer}>
            <p className={classes.upperCardText}>{item.title}</p>
            <p className={classes.lowerCardText}>{item.subtitle}</p>
          </div>
        </div>
      </>
    ));
    return arrayOfCards;
  };
  return (
    <>
      <div className={classes.container}>
        <div className={classes.containerLeft}>
          <img className={classes.checkImage} src={CHECK_IMAGE} alt="" />
          <p className={classes.youAreReadyText}>¡Ya estás listo!</p>
          <p className={classes.descriptionText}>
            Para poder brindarte el mejor servicio posible, te recomendamos
            agendar tu sesión 1 a 1 <strong> gratis,</strong> para armar tu plan
            financiero 100% a tu medida.
          </p>
          <a
            href={"https://calendly.com/rokinapp/consultoria-rokin"}
            target="_blank"
            className={classes.scheduleButton}
          >
            <img src={CALENDAR_ICON} alt="" />
            <span className={classes.scheduleText}>Agendar mi coaching</span>
          </a>
          {!isMobile && (
            <>
              <p className={classes.explorePlatformText}>
                ¡O, si prefieres puedes explorar la plataforma por tu cuenta! Y
                tomar el test de nuevo cuando quieras.
              </p>
              <ButtonOutlined
                className={classes.goExploreRokinButton}
                text="Ir a explorar Rokin"
                onClick={() => {
                  navigate(Route.finance);
                }}
              />
            </>
          )}
        </div>
        <div className={classes.containerRight}>
          <div className={classes.containerUpperRight}>
            <p className={classes.upperText}>
              Para lograr tus metas, y mejorar tu bienestar financiero te
              recomendamos los siguientes servicios:
            </p>
            <div className={classes.cardsContainer}>
              {getServicesCards(cardsCase)}
            </div>
            <div
              className={classes.goToServicesButton}
              onClick={() => {
                navigate(Route.services);
              }}
            >
              <span className={classes.goToServicesText}>
                Ir a los servicios
              </span>
              <KeyboardArrowRightOutlinedIcon htmlColor="#FFFFFF" />
            </div>
          </div>
          <div className={classes.containerUpperRight}>
            <p className={classes.upperText}>
              Y las siguientes herramientas, que te ayudarán a tomar control de
              tus finanzas.
            </p>
            <div className={classes.cardsContainer}>
              {getToolCards(cardsCase)}
            </div>
            <div
              className={classes.goToServicesButton}
              onClick={() => {
                navigate(Route.rokipedia + "/#tools");
              }}
            >
              <span className={classes.goToServicesText}>
                Ir a las herramientas
              </span>
              <KeyboardArrowRightOutlinedIcon htmlColor="#FFFFFF" />
            </div>
          </div>
          {isMobile && (
            <>
              <p className={classes.explorePlatformText}>
                ¡O, si prefieres puedes explorar la plataforma por tu cuenta! Y
                tomar el test de nuevo cuando quieras.
              </p>
              <ButtonOutlined
                className={classes.goExploreRokinButton}
                text="Ir a explorar Rokin"
                onClick={() => {
                  navigate(Route.finance);
                }}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

const servicesListCase1 = [
  {
    id: 1,
    title: "Administrador de créditos",
    subtitle: "Te ayudamos a refinanciar o consolidar tus créditos",
    link: "/services/credits",
  },
  {
    id: 2,
    title: "Asistente Financiero",
    subtitle: "Acompañamiento continuo en tu bolsillo",
    link: "/services",
  },
];

const servicesListCase2 = [
  {
    id: 1,
    title: "Consulta Financiera",
    subtitle: "Sesión única 1 a 1",
    link: "/services",
  },
  {
    id: 2,
    title: "Administrador de créditos",
    subtitle: "Te ayudamos a refinanciar o consolidar tus créditos",
    link: "/services/credits",
  },
  {
    id: 3,
    title: "Análisis de pagos recurrentes",
    subtitle: "Evaluamos qué planes tienes y si puedes pagar menos",
    link: "/services",
  },
];

const servicesListCase3 = [
  {
    id: 1,
    title: "Coaching anual",
    subtitle: "Construcción de un plan financiero 1 a 1",
    link: "/services",
  },
  {
    id: 2,
    title: "Comparador de inversiones",
    subtitle: "Encuentra la inversión ideal para ti",
    link: "/services/Investments",
  },
  {
    id: 3,
    title: "Análisis de pagos recurrentes",
    subtitle: "Evaluamos qué planes tienes y si puedes pagar menos",
    link: "/services",
  },
];

const servicesListCase4 = [
  {
    id: 1,
    title: "Asistente Financiero",
    subtitle: "Acompañamiento continuo en tu bolsillo",
    link: "/services",
  },
  {
    id: 2,
    title: "Rokin Plus",
    subtitle: "Programa de 7 pasos",
    link: "/services",
  },
  {
    id: 3,
    title: "Análisis de pagos recurrentes",
    subtitle: "Evaluamos qué planes tienes y si puedes pagar menos",
    link: "/services",
  },
];

const servicesListCase5 = [
  {
    id: 1,
    title: "Asistente Financiero",
    subtitle: "Acompañamiento continuo en tu bolsillo",
    link: "/services",
  },
  {
    id: 2,
    title: "Rokin Plus",
    subtitle: "Programa de 7 pasos",
    link: "/services",
  },
  {
    id: 3,
    title: "Administrador de créditos",
    subtitle: "Te ayudamos a refinanciar o consolidar tus créditos",
    link: "/services/credits",
  },
];

const toolsListCase1 = [
  {
    id: 1,
    title: "Categorizador de gastos",
    subtitle: "Lleva un registro de tus hábitos financieros",
    link: "/myFinances",
  },
  {
    id: 2,
    title: "Traductor de crédito",
    subtitle: "Ten claro cuánto pagarás por un crédito",
    link: "/tools/creditTranslator",
  },
  {
    id: 3,
    title: "Análisis de refinanciamiento",
    subtitle: "Descubre si te conviene refinanciar un crédito",
    link: "/tools/refinance/refinanceCredit",
  },
];

const toolsListCase2 = [
  {
    id: 1,
    title: "Categorizador de gastos",
    subtitle: "Lleva un registro de tus hábitos financieros",
    link: "/myFinances",
  },
  {
    id: 2,
    title: "Traductor de crédito",
    subtitle: "Ten claro cuánto pagarás por un crédito",
    link: "/tools/creditTranslator",
  },
  {
    id: 3,
    title: "Análisis de refinanciamiento",
    subtitle: "Descubre si te conviene refinanciar un crédito",
    link: "/tools/refinance/refinanceCredit",
  },
];

const toolsListCase3 = [
  {
    id: 1,
    title: "Test Perfil de Inversión",
    subtitle: "Descubre que tipo de inversionista eres",
    link: "/tools/creditsAndDebts/step3/profileInvestTest",
  },
  {
    id: 2,
    title: "Portal de inversión",
    subtitle: "Crea un objetivo de ahorro e invierte para lograrlo",
    link: "/Investments",
  },
  {
    id: 3,
    title: "Categorizador de gastos",
    subtitle: "Lleva un registro de tus hábitos financieros",
    link: "/myFinances",
  },
];

const toolsListCase4 = [
  {
    id: 1,
    title: "Centro de cuentas",
    subtitle: "Agrega tus bancos y encuentras los mejores descuentos",
    link: "/myFinances",
  },
  {
    id: 2,
    title: "Categorizador de gastos",
    subtitle: "Lleva un registro de tus hábitos financieros",
    link: "/myFinances",
  },
  {
    id: 3,
    title: "Rokipedia",
    subtitle: "Aprende sobre el mundo financiero",
    link: "/rokipedia",
  },
];

const toolsListCase5 = [
  {
    id: 1,
    title: "Rokipedia",
    subtitle: "Aprende sobre el mundo financiero",
    link: "/rokipedia",
  },
  {
    id: 2,
    title: "Categorizador de gastos",
    subtitle: "Lleva un registro de tus hábitos financieros",
    link: "/myFinances",
  },
  {
    id: 3,
    title: "Análisis de refinanciamiento",
    subtitle: "Descubre si te conviene refinanciar un crédito",
    link: "/tools/refinance/refinanceCredit",
  },
];

export default YouAreSet;
