import { Theme, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles<Theme, { isMobile: boolean }>((theme) => ({
  container: {
    color: '#ffffff',
    fontFamily: 'Nunito',
    minHeight: "60vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down(900)]: {
      padding: '0.8rem 0 0rem',
    },
  },
  image: {
    margin: '0 auto',
    maxWidth: '27rem',
    textAlign: 'center',
    minHeight: '20rem',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 500,
    margin: '2.20rem 0',
    textAlign: 'center',
  },
  subtitle: {
    fontSize: '1rem',
    fontWeight: 300,
    width: '21rem',
    margin: '0 auto',
    textAlign: 'center',
  },
  containerTexts: {
    height: '10rem',
  },
  iconArrow: {
    background: 'rgba(0, 0,0,0.5)',
    borderRadius: 10,
    width: '2.5rem',
    height: '2.5rem',
    paddingLeft: 10,
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0.5rem',
    [theme.breakpoints.down(800)]: {
      marginTop: 0,
    },
  },
  textArrow: {
    fontSize: '2rem',
    width: '22rem',
    marginLeft: '1.5rem',
    fontFamily: 'Montserrat',
    [theme.breakpoints.down(800)]: {
      fontSize: '1rem',
      width: '12rem',
      marginLeft: '1rem',
    },
  },
  containerArrow: {
    display: 'flex',
    [theme.breakpoints.down(800)]: {
      alignItems: 'center',
      width: '90%',
      margin: '0 auto',
    },
  },
  assessment: {
    display: 'flex',
    [theme.breakpoints.down(1240)]: {
      flexDirection: 'column'
    }
  },
  assessmentItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 2rem',
    gap: '1.3rem',
  },
  assessmentItemLine: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 2rem',
    gap: '1.3rem',
    borderRight: ({isMobile}) => isMobile ? "": "0.5px solid rgb(244 243 243 / 40%)",
    borderBottom: ({ isMobile }) => isMobile ? "0.5px solid rgb(244 243 243 / 40%)" : "",
    borderImage: ({isMobile}) => isMobile ? "" : "linear-gradient(transparent 20%, #D1D1D1 30% 70%, transparent 80%) 0 1 0 0 / 0.5px"
  },
  assessmentItemText: {
    fontSize: '1rem',
    fontWeight: 500,
    fontFamily: 'Montserrat',
    whiteSpace: "nowrap",
    display: "inline-block",
    "& span": {
      display: "block"
    },
    [theme.breakpoints.down(800)]: {
      fontSize: '1rem',
    },
  },
  assessmentItemInfo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: "1rem",
  },
  assessmentItemInfoText: {
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    color: '#00323c',
    marginLeft: '0.5rem',
    [theme.breakpoints.down(800)]: {
      fontSize: '0.8rem',
    },
  },
  infoIcon: {
    height: '2rem',
  },
  assessmentItemInput: {
    width: '100%',
    '& fieldset': {
      borderColor: 'white',
    },
    '& label': {
      color: 'white',
    },
    '& input': {
      color: 'white',
    },
  },
  assessmentItemSiNo: {
    '& .MuiFormControlLabel-label': {
      color: 'white',
      fontSize: '1rem',
      fontFamily: 'Montserrat',
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: 'white',
    },
  },
  linea: {
    width: '100%',
    height: '0.5px',
    background: '#f4f3f3',
    margin: '1rem 0',
  },

  button: {
    width: '175px',
    margin: '4rem 0rem 4rem auto',
    [theme.breakpoints.down(800)]: {
      margin: '4rem auto 0',
    },
    '& button': {
      background: theme.palette.primary.dark,
    },
    '& button:hover': {
      background: theme.palette.primary.dark,
    },
  },
  toggleButton: {
    "& .MuiToggleButton-root": {
      border: "1px solid #00323c",
      padding: "0.2rem 1.5rem 0.2rem 1.5rem",
      borderRadius: "0.75rem"
    },
    "& .MuiToggleButton-root.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#00323c"
    },
    "& .MuiToggleButtonGroup-groupedHorizontal:not(:first-child)": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    "& .MuiToggleButtonGroup-groupedHorizontal:not(:last-child)": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    }
  }
}));
