import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles<
  Theme,
  {
    color: string;
    step: number;
  }
>((theme) => ({
  containerLayout: {
    background: ({ step }) =>
      step == 0 || step == 1 || step == 5 ? "#00323C" : "#008195",
    padding: "3rem",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down(900)]: {
      padding: ({ step }) => (step == 5 ? "0" : "1.5rem"),
      bottom: "0rem",
    },
    [theme.breakpoints.down(580)]: {
      height: ({ step }) => (step == 4 ? "100%" : "100vh"),
    },
  },

  last: {
    position: "initial",
    [theme.breakpoints.down(900)]: {
      bottom: "-20rem",
      position: ({ step }) => (step == 4 ? "static" : "fixed"),
    },
    [theme.breakpoints.down(600)]: {
      bottom: "-13rem",
    },
  },

  containerChildren: {
    margin: "0rem 6rem",
    [theme.breakpoints.down(900)]: {
      margin: "auto",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: ({ step }) => (step == 0 || step == 1 ? "0 3rem  0 3rem" : "0rem"),
    [theme.breakpoints.down(900)]: {
      padding: "1.5rem 1rem 1rem 1.5rem",
    },
    "& button": {
      color: "white",
    },
  },
  buttonSecondary: {
    textDecoration: "none",
    opacity: 0.4,
    fontSize: "1rem",
  },
}));
