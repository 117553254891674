import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "grid",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "2rem",
    marginTop: "3rem",
    gridTemplateColumns: "40% 60%",
    [theme.breakpoints.down(1240)]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  containerRight: {
    color: "#FFFFFF",
    fontFamily: "Nunito",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  upperRightText: {
    fontSize: "1rem",
    fontWeight: 500,
    color: "#FFFFFF",
    fontFamily: "Montserrat",
  },
  lowerRightText: {
    fontSize: "0.875rem",
    fontWeight: 500,
    color: "#FFFFFF",
    fontFamily: "Montserrat",
  },
  image: {
    margin: "0 auto",
    maxWidth: "27rem",
    textAlign: "center",
    minHeight: "20rem",
  },
  title: {
    fontSize: "2rem",
    fontWeight: 500,
    margin: "2.20rem 0",
    textAlign: "center",
  },
  subtitle: {
    fontSize: "1rem",
    fontWeight: 300,
    width: "21rem",
    margin: "0 auto",
    textAlign: "center",
  },
  containerTexts: {
    height: "10rem",
  },
  iconArrow: {
    background: "rgba(0, 0,0,0.5)",
    borderRadius: 10,
    width: "2.5rem",
    height: "2.5rem",
    paddingLeft: 10,
    display: "flex",
    justifyContent: "center",
    marginTop: "0.5rem",
    [theme.breakpoints.down(1240)]: {
      marginTop: 0,
    },
  },
  textArrow: {
    fontSize: "2rem",
    width: "22rem",
    marginLeft: "1.5rem",
    fontFamily: "Montserrat",
    [theme.breakpoints.down(1240)]: {
      fontSize: "1rem",
      width: "12rem",
      marginLeft: "1rem",
    },
  },
  containerArrow: {
    display: "flex",
    [theme.breakpoints.down(1240)]: {
      alignItems: "center",
      width: "90%",
      margin: "0 auto",
    },
  },
  containerCard: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    justifyItems: "center",
    alignItems: "center",
    gap: "0.7rem",
    [theme.breakpoints.down(900)]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    [theme.breakpoints.down(580)]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.down(400)]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  card: {
    background: "white",
    width: "100%",
    height: "8rem",
    borderRadius: "1rem",
    fontFamily: "Nunito",
    fontSize: "0.9rem",
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    cursor: "pointer",
    position: "relative",
    [theme.breakpoints.down(1240)]: {
      fontSize: 12,
      padding: "1rem 0.92rem",
    },
    "& span": {
      display: "block",
      color: theme.palette.text.secondary,
      paddingTop: "0.7rem",
    },
    "& img": { width: "2.5rem", height: "2rem" },
    padding: "1rem 0.9rem",
  },
  select: {
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    border: "1px solid #00a9c2",
    padding: "1rem 0.8rem",
    "& span": {
      color: "#00a9c2",
    },
  },
  button: {
    display: "flex",
    justifyContent: "flex-end",
    "& button": {
      background: theme.palette.primary.dark,
      [theme.breakpoints.down(500)]: {
        width: "100%",
      },
    },
    "& button:hover": {
      background: theme.palette.primary.dark,
    },
  },
  imgSelected: {
    width: "2.5rem",
    height: "2rem",
  },
  checkIcon: {
    color: "#00a9c2",
    position: "absolute",
    top: "0.75rem",
    right: "0.75rem",
  },
}));
