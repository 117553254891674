import React, { useEffect, useState } from "react";
import { useStyles } from "./AnalysisResults.style";
import Progressbar from "react-js-progressbar";
import { SALUDABLE_ICON, AT_RISK_ICON, HAS_POTENTIAL, IS_CRITICAL } from "images/AWS/productTour";
import { financialAnalysisResponse } from "@interfaces";

interface AnalysisResultsProps {
  analysisScore: financialAnalysisResponse;
  isTitle?: boolean;
}

const AnalysisResults = ({
  analysisScore,
  isTitle,
}: AnalysisResultsProps) => {
  const classes = useStyles();

  const percentage = analysisScore.score * 100 / 30
  const [iconToUse, setIconToUse] = useState(IS_CRITICAL)

  useEffect(() => {
    switch (analysisScore.status) {
      case ("Saludable"):
        setIconToUse(SALUDABLE_ICON)
        break;
      case ("Con potencial"):
        setIconToUse(HAS_POTENTIAL)
        break;
      case ("En riesgo"): 
        setIconToUse(AT_RISK_ICON)
        break;
      case ("Crítical"):
        setIconToUse(IS_CRITICAL)
        break;
    }
  }, [analysisScore])

  return (
    <>
      <section className={classes.containerLeft}>
        {isTitle && (<p className={classes.yourResultsText}>Tus Resultados</p>)}
        <div className={classes.evaluationGraphAndScoreContainer}>
          <div className={classes.evaluationGraphContainer}>
            <Progressbar
              shape="arc"
              animateText={true}
              clockwise={false}
              input={percentage}
              pathWidth={6}
              pathColor={"#00a9c7"} // use an array for gradient color.
              trailWidth={6}
              trailColor="#D3D3D3" // use a string for solid color.
              textStyle={{ fill: "#FFFFFF" }} // middle text style
              pathLinecap="round"
              customText={analysisScore.score.toString()}
            />
            <span>puntos</span>
          </div>
          <div className={classes.evaluationScoreContainer}>
            <div className={classes.scoreImageContainer}>
              <img
                className={classes.scoreImage}
                src={iconToUse}
                alt=""
              />
            </div>
            <p className={classes.evaluationScoreText}>{analysisScore.status}</p>
          </div>
        </div>
        <div className={classes.evaluationDescriptionContainer}>
          <p>
            {analysisScore.description}
          </p>
        </div>
      </section>
    </>
  );
};

export default AnalysisResults;
