import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  containerLeft: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "flex-start",
    width: "100%",
    gap: "1rem",
    maxWidth: "25rem",
  },
  yourResultsText: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#FFFFFF",
    fontFamily: "Montserrat",
  },
  evaluationGraphAndScoreContainer: {
    display: "flex",
    gap: "1rem",
    width: "100%",
  },
  evaluationDescriptionContainer: {
    backgroundColor: "#00323C",
    borderRadius: "16.9px",
    padding: "0.5rem 2rem",
    width: "100%",
    "& p": {
      fontSize: "0.926rem",
      fontWeight: "bold",
      color: "#FFFFFF",
      fontFamily: "Nunito",
    },
  },
  evaluationGraphContainer: {
    backgroundColor: "#00323C",
    height: "8.7rem",
    width: "100%",
    padding: "1.6rem 0 0 0 ",
    display: "flex",
    alignItems: "center",
    borderRadius: "16.9px",
    justifyContent: "center",
    "& svg": {
      height: "8.7rem",
      width: "12.7rem",
      flexGrow: 0,
      marginRight: "-3rem",
      marginTop: "1.5rem",
    },
    "& span": {
      position: "relative",
      color: "#FFFFFF",
      top: "1.3rem",
      left: "-4.7rem",
    },
  },
  evaluationScoreContainer: {
    height: "8.7rem",
    width: "100%",
    backgroundColor: "#00323C",
    borderRadius: "16.9px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
    padding: "1rem 1.8rem",
  },
  scoreImageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "36px",
    border: "1px solid #00A9C7",
    height: "3.3rem",
    width: "3.3rem",
  },
  scoreImage: {},
  evaluationScoreText: {
    fontSize: "0.926rem",
    fontWeight: "bold",
    color: "#FFFFFF",
    fontFamily: "Montserrat",
  },
}));
