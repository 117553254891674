import React, { SetStateAction, useEffect, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useStyles } from './CardAssessment.style';
import { INFO_ICON } from 'images/AWS/habits';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { CheckRounded } from '@material-ui/icons';
import { NumericInput } from '@components/Reusables';
import { financialAnalysisInterface } from '@interfaces';

interface CardAssessmentProps {
  setUserIdea: (value: SetStateAction<financialAnalysisInterface>) => void;
  step: number;
}

const CardAssessment = ({ setUserIdea, step }: CardAssessmentProps) => {
  const isMobile = useMediaQuery('(max-width: 1240px)');
  const classes = useStyles({isMobile: isMobile});
  const assessmentList1 = assessmentList.slice(0, 3);
  const assessmentList2 = assessmentList.slice(3, 6);

  const [income, setIncome] = useState<number>(0)
  const [fixedExpense, setFixedExpense] = useState<number>(0)
  const [varExpense, setVarExpense] = useState<number>(0)
  const [savingsAndInvestments, setSavingsAndInvestments] = useState<number>(0)
  const [loansAndDebt, setLoansAndDebt] = useState<number>(0)
  const [defaultDebt, setDefaultDebt] = useState<number>(0)

  const [savingsToggle, setSavingToggle] = useState<boolean>(true)
  const [loansToggle, setLoansToggle] = useState<boolean>(false)
  const [defaultDebtToggle, setDefaultDebtToggle] = useState<boolean>(false)

  const [assessmentListFilter, setAssessmentListFilter] =
    useState(assessmentList1);
  useEffect(() => {
    if (step === 3) {
      setAssessmentListFilter(assessmentList2);
    } else if (step === 2) {
      setAssessmentListFilter(assessmentList1);
    }
  }, [step]);

  const handleNumericValue = (id: number) => { 
    switch (id) {
      case 0:
        return income
      case 1:
        return fixedExpense
      case 2:
        return varExpense
      case 3:
        return savingsAndInvestments
      case 4:
        return loansAndDebt
    }
  }
  
  const handleNumericOnChange = (id: number, value: any) => {
    switch (id) {
      case 0:
        setIncome(value)
        break;
      case 1:
        setFixedExpense(value)
        break;
      case 2:
        setVarExpense(value)
        break;
      case 3:
        setSavingsAndInvestments(value)
        break;
      case 4:
        setLoansAndDebt(value)
        break;
    }
  }

  const handleToggle = (id: number, justValue?: boolean) => {
    switch (id) {
      case 3: 
        if (justValue) {
          return savingsToggle
        } else {
          setSavingToggle(!savingsToggle)
          break;
        }
      case 4: 
        if (justValue) {
          return loansToggle
        } else {
          setLoansToggle(!loansToggle)
          break;
        }
      case 5:
        if (justValue) {
          return defaultDebtToggle
        } else {
          setDefaultDebtToggle(!defaultDebtToggle)
          break;
        }
      default:
        return true
    }
  }

  useEffect(() => {
    if(!savingsToggle) setSavingsAndInvestments(0)
    if(!loansToggle) setLoansAndDebt(0)
    if(!defaultDebtToggle) setDefaultDebt(0)
  }, [savingsToggle, loansToggle, defaultDebtToggle])

  useEffect(() => {
    setUserIdea({
      averageIncome: income,
      debts: loansAndDebt,
      defaultDebts: defaultDebtToggle,
      fixedExpenses: fixedExpense,
      savings: savingsAndInvestments,
      variableExpenses: varExpense,
    })
  }, [income, fixedExpense, varExpense, savingsAndInvestments, loansAndDebt, defaultDebt])

  return (
    <>
      <section className={classes.container}>
        <div className={classes.assessment}>
          {assessmentListFilter.map((item) => {
            return (
              <div className={item.linea ? classes.assessmentItemLine : classes.assessmentItem}>
                <div className={classes.assessmentItemText}>
                  {item.text}
                  <span>{item.subText}</span>
                </div>
                {item.SiNo && (
                  <ToggleButtonGroup
                    className={classes.toggleButton}
                    color='primary'
                    value={handleToggle(item.id, true)}
                    exclusive
                    onChange={(e) => handleToggle(item.id)}
                    aria-label='Platform'
                  >
                    <ToggleButton value={true} aria-label='left aligned'>
                      {handleToggle(item.id, true) && (<CheckRounded width={"0.8rem"} height={"0.8rem"} />)} Si
                    </ToggleButton>
                    <ToggleButton value={false} aria-label='centered'>
                      {!handleToggle(item.id, true) &&(<CheckRounded width={"0.8rem"} height={"0.8rem"} />)} No
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
                {item.input && (
                  <NumericInput
                    name={item.inputText}
                    label={item.inputText}
                    value={handleNumericValue(item.id)}
                    onChange={(e) => handleNumericOnChange(item.id, e.target.value)}
                    placeholder={item.inputText}
                    className={classes.assessmentItemInput}
                    disabled={!handleToggle(item.id, true)}
                    error={false}
                  />
                )}
                {item.infoText && (
                  <div className={classes.assessmentItemInfo}>
                    <img
                      src={INFO_ICON}
                      alt='info'
                      className={classes.infoIcon}
                    />
                    <div className={classes.assessmentItemInfoText}>
                      {item.infoText}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default CardAssessment;

const assessmentList = [
  {
    id: 0,
    text: (<p>Tu <strong>ingreso</strong></p>),
    subText: '(promedio al mes)',
    inputText: 'Total ingresos',
    infoText: 'Ingresos fijos + variables + arriendos, pueden ser aproximados.',
    SiNo: false,
    input: true,
    linea: true,
  },
  {
    id: 1,
    text: (<p>Tus <strong>gastos fijos</strong></p>),
    subText: '(promedio al mes)',
    inputText: 'Total gastos fijos',
    infoText:
      'Gastos esenciales, como arriendo, luz, agua, gas, supermercado, telefonía, etc.',
    SiNo: false,
    input: true,
    linea: true,
  },
  {
    id: 2,
    text: (<p>Tus <strong>gastos variables</strong></p>),
    subText: '(promedio al mes)',
    inputText: 'Total gastos variables',
    infoText:
      'Gastos que haces a veces como ropa, restaurantes, regalos, entretención, etc.',
    SiNo: false,
    input: true,
    linea: false,
  },
  {
    id: 3,
    text: '¿Tienes ahorros o inversiones?',
    subText: null,
    inputText: 'Total ahorrado',
    infoText:
      'Incluye saldos en cuenta de ahorro, depósitos a plazo, fondos mutuos, etc.',
    SiNo: true,
    input: true,
    linea: true,
  },
  {
    id: 4,
    text: '¿Tienes creditos o deudas?',
    subText: null,
    inputText: 'Total deudas mensual',
    infoText:
      'Incluye tu pago mensual de créditos de consumo, hipotecario, automotriz y tarjetas.',
    SiNo: true,
    input: true,
    linea: true,
  },
  {
    id: 5,
    text: '¿Tienes alguna deuda morosa?',
    subText: null,
    inputText: '',
    infoText: '',
    SiNo: true,
    input: false,
    linea: false,
  },
];
